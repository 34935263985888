const state = {
  refreshKey: Date.now(), // UNIXタイムスタンプで初期化,
};

const getters = {
  getRefreshKey: (state) => state.refreshKey,
};

const mutations = {
  SET_REFRESH_KEY(state) {
    state.refreshKey = Date.now(); // UNIXタイムスタンプで更新
  },
};

const actions = {
  refreshHome({ commit }) {
    commit("SET_REFRESH_KEY");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
