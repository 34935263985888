const state = {
  shopSiteList: [],
  defaultShopSiteId: 0,
};

const getters = {
  getShopSiteList: (state) => state.shopSiteList,
  getDefaultShopSiteId: (state) => state.defaultShopSiteId,
  getShopSiteById: (state) => (id) => {
    const shopSite = state.shopSiteList.find((r) => r.id == id);
    return shopSite;
  },
};

const actions = {
  createShopSite({ commit }, value) {
    commit("createShopSite", value);
  },
  updateShopSite({ commit }, value) {
    commit("updateShopSite", value);
  },
  deleteShopSite({ commit }, shop_site_id) {
    commit("deleteShopSite", shop_site_id);
  },
  setShopSiteList({ commit }, value) {
    commit("setShopSiteList", value);
  },
  setDefaultShopSiteId({ commit }, value) {
    commit("setDefaultShopSiteId", value);
  },
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = {
  createShopSite(state, value) {
    state.shopSiteList.push(value);
  },
  updateShopSite(state, value) {
    state.shopSiteList.forEach(function (shop_site, index) {
      if (value.id == shop_site.id) {
        state.shopSiteList.splice(index, 1, value);
      }
    });
  },
  deleteShopSite(state, shop_site_id) {
    state.shopSiteList.forEach(function (shop_site, index) {
      if (shop_site_id == shop_site.id) {
        state.shopSiteList.splice(index, 1);
      }
    });
  },
  setShopSiteList(state, array) {
    state.shopSiteList.splice(0);
    for (var i = 0; i < array.length; i++) {
      state.shopSiteList.push(array[i]);
    }
    state.defaultShopSiteId = array.length >= 1 ? array[0].id : 0;
  },
  setDefaultShopSiteId(state, value) {
    state.defaultShopSiteId = value;
  },
  clear(state) {
    state.shopSiteList = [];
    state.defaultShopSiteId = 0;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
