import Repository from "../repository";

const resource = "/shop_site_x_accounts";

export default {
  get_x_state(state) {
    return Repository.get(`${resource}/get_x_state/${state}`);
  },
  list_shopsite(payload, headers = {}) {
    return Repository.get(`${resource}/list_shopsite`, {
      params: payload,
      headers: headers,
    });
  },
  get_account_count(payload, headers = {}) {
    return Repository.post(`${resource}/get_account_count`, payload, {
      headers,
    });
  },
  update_sort(payload) {
    return Repository.put(`${resource}/update_sort`, payload);
  },
  // save_all(payload) {
  //   return Repository.post(`${resource}/save_all`, { shop_prop: payload });
  // },
  // get(castPropId) {
  //   return Repository.get(`${resource}/get/${castPropId}`);
  // },
  // get_all(payload) {
  //   return Repository.post(`${resource}/get_all`, payload);
  // },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // update(payload) {
  //   return Repository.put(`${resource}/update`, payload);
  // },
  // delete(castPropId) {
  //   return Repository.delete(`${resource}/delete/${castPropId}`);
  // },
};
