<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu nav_border"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <v-list>
      <v-list-item class="px-2">
        <v-img max-width="60%" src="@/assets/images/yatoite-logo.svg"></v-img>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav>
      <v-list-item>
        <!--
        <v-list-item-icon>
          <v-icon>mdi-store</v-icon>
        </v-list-item-icon>
        -->
        <v-list-item-title>
          <v-select
            v-model="selectedShop"
            :items.sync="shops"
            item-value="id"
            item-text="name"
            no-data-text="店舗を登録して下さい"
            label="店舗選択"
            return-object
            :disabled="loading"
          ></v-select>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <template v-for="menu in menus">
        <v-list-item
          v-if="!menu.child_menu"
          :key="menu.name"
          :to="menu.to"
          @click="menu_click(menu.action)"
          link
          :disabled="loading"
          :option="menu.option"
          ref="refMenu"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-else
          no-action
          v-model="menu.active"
          :key="menu.name"
          :prepend-icon="menu.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="c_menu in menu.child_menu"
            :key="c_menu.title"
            :to="c_menu.to"
            @click="menu_click(menu.action)"
            link
            :disabled="loading"
          >
            <v-list-item-title>
              {{ c_menu.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  watch,
  ref,
  onMounted,
} from "@vue/composition-api";
import { useRouter } from "@/utils";
import store from "@/store";
import modelShopSite from "@/model/shopSite.js";
import utilFunc from "@/common/utilFunc.js";
import setting from "@/common/setting.js";
import ContractDialogComponent from "@/views/admin/contractEdit/component/contractDialogComponent.vue";

export default defineComponent({
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup(_, ctx) {
    const { router } = useRouter();
    const refMenu = ref(null);
    const state = reactive({
      selectedShop: store.getters["shops/currentShop"],
      shops: store.getters["shops/allList"],
    });
    let loading = ref(store.getters["loadingIcon/isLoading"].loading);

    const menus = [
      {
        title: "ホーム",
        icon: "mdi-home",
        to: "/home",
        action: "group_close",
        option: "none",
      },
      {
        title: "キャスト管理",
        icon: "mdi-face-woman",
        to: "/cast",
        action: "group_close",
        option: "none",
      },
      {
        title: "スケジュール管理",
        icon: "mdi-calendar-month",
        to: "/schedule",
        action: "group_close",
        option: "none",
      },
      {
        title: "更新コンテンツ",
        icon: "mdi-update",
        to: "/synchro-contents",
        action: "group_close",
        option: "none",
      },
      {
        title: "テンプレート管理",
        icon: "mdi-clipboard-list-outline",
        to: "/template",
        action: "group_close",
        option: "none",
      },
      {
        title: "勤務ステータス更新",
        icon: "mdi-account-clock",
        to: "/working",
        action: "group_close",
        option: "none",
      },
      {
        title: "更新結果履歴",
        icon: "mdi-book-open-page-variant",
        to: "/result-log",
        action: "group_close",
        option: "none",
      },
      {
        title: "媒体管理",
        icon: "mdi-web",
        to: "/setting-synchro",
        action: "group_close",
        option: "none",
      },
      {
        title: "店舗管理",
        icon: "mdi-store",
        to: "/setting-shop",
        action: "group_close",
        option: "none",
      },
      {
        title: "ユーザー設定",
        icon: "mdi-card-account-details",
        to: "/setting-basic",
        action: "group_close",
        option: "none",
      },
      {
        title: "ホームページ連携管理",
        icon: "mdi-link-variant",
        to: "/hp-link",
        action: "group_close",
        option: setting.plan_code_hp_link,
      },
      {
        title: "X連携管理",
        icon: "mdi-link-variant",
        to: "/x-link",
        action: "group_close",
        option: setting.plan_code_x_link,
      },
      {
        title: "ログアウト",
        icon: "mdi-logout",
        to: "",
        action: "logout",
        option: "none",
      },
    ];

    // メニューをクリックした時
    const menu_click = async (menu_action) => {
      switch (menu_action) {
        case "group_close":
          menus.forEach((menus) => (menus.active = false));
          break;
        case "logout":
          if (window.confirm("ログアウトしますか？")) {
            router.push({ name: "login" });
            store.dispatch("shop_sites/clear");
            // store.dispatch("templates/clear");
          }
      }
    };

    // 店舗を切り替えた時、vuexのStoreを更新
    watch(state, async () => {
      // 店舗が切り替わってない場合は何もしない
      if (store.getters["shops/currentShop"].id == state.selectedShop.id) {
        return;
      }

      // 店舗チェック
      const check = await utilFunc.checkShop(state.selectedShop.id);
      if (!check) {
        router.push({ name: "login" });
        return;
      }

      store.dispatch("shops/setCurrentShop", state.selectedShop);

      // キャスト、更新媒体のクリア
      store.dispatch("shop_sites/clear");
      // store.dispatch("templates/clear");

      // ホーム画面に遷移
      if (router.currentRoute.path == "/home") {
        // ホーム画面で店舗を切り替えられた時、再描画
        await modelShopSite.setStore();
        store.dispatch("home/refreshHome");
      } else {
        router.push({ name: "home" }).catch((err) => {});
      }

      // オプション契約メニューの表示を切り替える
      await viewMenuOption();
    });

    // ローディングアイコン表示
    watch(store.getters["loadingIcon/isLoading"], (state) => {
      loading.value = state.loading;
    });

    const viewMenuOption = async () => {
      // option契約されていないメニューは非表示にする
      const currentShop = store.getters["shops/currentShop"];
      // console.log(refMenu.value);
      for (let i = 0; i < refMenu.value.length; i++) {
        if (refMenu.value[i].$attrs.option == "none") {
          continue;
        }
        if (
          (await checkMenuOption(
            currentShop,
            refMenu.value[i].$attrs.option
          )) != undefined
        ) {
          refMenu.value[i].$el.style.display = "";
        } else {
          refMenu.value[i].$el.style.display = "none";
        }
      }
    };

    const checkMenuOption = async (currentShop, OP) => {
      const count = await utilFunc.getValidOptionCount(
        currentShop.data.valid_option,
        OP
      );
      // console.log(OP, currentShop.data.valid_option, count);
      return count > 0 ? true : undefined;
    };

    onMounted(async () => {
      await viewMenuOption();
    });

    return {
      refMenu,
      menu_click,
      ...toRefs(state),
      menus,
      loading,
      checkMenuOption,
      viewMenuOption,
    };
  },
});
</script>

<style scoped>
.nav_border {
  border-right: 1px solid #eeeeee;
}
</style>
