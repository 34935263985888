import UserRepository from "./models/usersRepository";
import ShopRepository from "./models/shopsRepository";
import ShopPropRepository from "./models/shopPropsRepository";
import SiteRepository from "./models/sitesRepository";
import ShopSiteRepository from "./models/shopSitesRepository";
import ShopSiteContentRepository from "./models/shopSiteContentsRepository";
import CastRepository from "./models/castsRepository";
import CastPropRepository from "./models/castPropsRepository";
import CastImageRepository from "./models/castImagesRepository";
import TestRepository from "./models/testsRepository";
import ScheduleRepository from "./models/schedulesRepository";
import SchedulePatternRepository from "./models/schedulePatternsRepository";
import SiteContentRepository from "./models/siteContentsRepository";
import SiteItemRepository from "./models/siteItemsRepository";
import TemplateRepository from "./models/templatesRepository";
import ContentFormatRepository from "./models/contentFormatsRepository";
import UpdateContentRepository from "./models/updateContentsRepository";
import ChildUpdateHeaderRepository from "./models/childUpdateHeadersRepository";
import ChildUpdateContentRepository from "./models/childUpdateContentsRepository";
import ResultLogRepository from "./models/resultLogsRepository";
import WorkingCastRepository from "./models/workingCastsRepository";
import WorkingShopRepository from "./models/workingShopsRepository";
import InformationRepository from "./models/informationsRepository";

import ProviderRepository from "./models/providersRepository";
import planRepository from "./models/plansRepository";
import shopContractRepository from "./models/shopContractsRepository";
import shopContractDetailRepository from "./models/shopContractDetailsRepository";
import systemPropRepository from "./models/systemPropsRepository";
import billRepository from "./models/billsRepository";
import unclaimedContractRepository from "./models/unclaimedContractsRepository";
import updateCastupContentRepository from "./models/updateCastupContentsRepository";

import SynchroRepository from "./models/synchroRepository";
import FileRepository from "./models/fileRepository";
import OpenRepository from "./models/openRepository";

import ShopSiteApiKeyRepository from "./models/shopSiteApiKeysRepository";
import ShopSitePickupRepository from "./models/shopSitePickupsRepository";
import ShopSiteWhatsNewRepository from "./models/shopSiteWhatsNewsRepository";

import ShopSiteXAccountRepository from "./models/shopSiteXAccountsRepository";
import ShopSitePostCountRepository from "./models/shopSitePostCountsRepository";

import VerRepository from "./models/verRepository";

const repositories = {
  users: UserRepository,
  shops: ShopRepository,
  shopProps: ShopPropRepository,
  sites: SiteRepository,
  shopSites: ShopSiteRepository,
  shopSiteContents: ShopSiteContentRepository,
  casts: CastRepository,
  castProps: CastPropRepository,
  castImages: CastImageRepository,
  schedules: ScheduleRepository,
  schedulePatterns: SchedulePatternRepository,
  tests: TestRepository,
  siteContents: SiteContentRepository,
  siteItems: SiteItemRepository,
  templates: TemplateRepository,
  contentFormats: ContentFormatRepository,
  updateContents: UpdateContentRepository,
  childUpdateHeaders: ChildUpdateHeaderRepository,
  childUpdateContents: ChildUpdateContentRepository,
  resultLogs: ResultLogRepository,
  workingCasts: WorkingCastRepository,
  workingShops: WorkingShopRepository,
  informations: InformationRepository,
  synchro: SynchroRepository,
  file: FileRepository,
  ver: VerRepository,
  providers: ProviderRepository,
  plans: planRepository,
  shopContracts: shopContractRepository,
  shopContractDetails: shopContractDetailRepository,
  systemProps: systemPropRepository,
  bills: billRepository,
  unclaimedContracts: unclaimedContractRepository,
  updateCastupContents: updateCastupContentRepository,
  open: OpenRepository,
  shopSiteApiKeys: ShopSiteApiKeyRepository,
  shopSitePickups: ShopSitePickupRepository,
  shopSiteWhatsNews: ShopSiteWhatsNewRepository,
  shopSiteXAccounts: ShopSiteXAccountRepository,
  shopSitePostCounts: ShopSitePostCountRepository,
};

export const repositoryFactory = {
  get: (name) => repositories[name],
};

export const RepositoryError = (error) => {
  console.log(error);
};
