import Repository from "../repository";

const resource = "/shop_site_post_counts";

export default {
  get_all_count(payload, headers = {}) {
    return Repository.post(`${resource}/get_all_count`, payload, { headers });
  },
  // get(castPropId) {
  //   return Repository.get(`${resource}/get/${castPropId}`);
  // },
  // get_all(payload) {
  //   return Repository.post(`${resource}/get_all`, payload);
  // },
  // create(payload) {
  //   return Repository.post(`${resource}/create`, payload);
  // },
  // update(payload) {
  //   return Repository.put(`${resource}/update`, payload);
  // },
  // delete(castPropId) {
  //   return Repository.delete(`${resource}/delete/${castPropId}`);
  // },
};
